<!--
 * @Description: 渲染markdown文件
 * @Author: hai-27
 * @Date: 2020-03-12 17:30:46
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-12 18:23:58
 -->
<template>
  <div id="my-markdown" class="markdown-body">
    <vue-markdown :source="md"></vue-markdown>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'MyMarkdown',
  components: {
    VueMarkdown
  },
  data () {
    return {
      md: '###### 我们是一家致力于为客户提供创新解决方案的企业。我们一直专注于高品质保健品售卖，通过不断的积累和努力，我们推出了多种高质量的产品与服务，以满足市场的多样化需求。我们的团队由一群充满热情和创造力的专业人士组成，他们共同的信念是为客户提供卓越的体验和价值。我们的核心价值包括诚信、创新、卓越和责任。我们相信，通过团队的共同努力和对品质的不懈追求，我们能够超越客户的期望。'
    }
  },
  created () {
    // // 从后端请求README.md
    // this.$axios
    //     .get('/api/public/docs/README.md', {})
    //     .then(res => {
    //       this.md = res.data
    //     })
    //     .catch(err => {
    //       return Promise.reject(err)
    //     })
  }
}
</script>
<style>
@import "../assets/css/github-markdown.css";

.markdown-body {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 40px;
}
</style>